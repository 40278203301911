import React from 'react';
import {classes} from './BillingDetails.st.css';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {BillingAddressTitle} from '../BillingAddressTitle/BillingAddressTitle';
import {MemberBillingDetails} from './MemberBillingDetails';
import {BillingDetailsForm} from './BillingDetailsForm';
import {BillingSameAsShippingCheckbox} from './BillingSameAsShippingCheckbox';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
}

export interface BillingDetailsProps {
  billingInfo?: AddressWithContactModel;
  onSetBillingInfoChange: (billingInfo: AddressWithContactModel) => void;
}

export const BillingDetails = ({billingInfo, onSetBillingInfoChange}: BillingDetailsProps) => {
  const {
    memberStore: {isMember},
    checkoutStore: {isShippingFlow},
  } = useControllerProps();

  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      <BillingAddressTitle />
      {isShippingFlow && <BillingSameAsShippingCheckbox />}
      {isMember ? (
        <MemberBillingDetails billingInfo={billingInfo} onSetBillingInfoChange={onSetBillingInfoChange} />
      ) : (
        <BillingDetailsForm />
      )}
    </div>
  );
};
