import React from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {BillingAddressDropdown} from '../BillingAddressDropdown/BillingAddressDropdown';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';

export enum MemberBillingDetailsDataHook {
  root = 'MemberBillingDetails.root',
}

export interface BillingDetailsProps {
  billingInfo?: AddressWithContactModel;
  onSetBillingInfoChange: (billingInfo: AddressWithContactModel) => void;
}

export const MemberBillingDetails = ({billingInfo, onSetBillingInfoChange}: BillingDetailsProps) => {
  const {
    memberStore: {defaultAddress},
  } = useControllerProps();

  if (!billingInfo) {
    onSetBillingInfoChange(defaultAddress!);
  }

  return (
    <div data-hook={MemberBillingDetailsDataHook.root}>
      <BillingAddressDropdown />
      <ContactAndAddressSummary contact={billingInfo?.contact} address={billingInfo?.address} />
    </div>
  );
};
